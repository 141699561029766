import { mergeDeep } from '_utils'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import BaseMaterial from '../unseen/base/BaseMaterial'
import { Color, UniformsLib } from 'three'

export default class RootMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				uOpacity: { value: 1.0 },

				uBaseColor1: { value: new Color(0xb1936a) },
				// uBaseColor2: { value: new Color(0xb1936a) },
				// uBaseColor3: { value: new Color(0xb1936a) },
				uAmbient: { value: new Color(0x000000) },
				uShininess: { value: 25.0 },
				uSpecularStrength: { value: 1.5 },

				uDebugShadow: { value: false },
				...UniformsLib.lights
			},
			lights: true,
			defines: {
				DYNAMIC_SHADOWS: true
			},
			transparent: true
		}, options)

		super(options)
	}
}

import { mergeDeep } from "_utils/index"
import createComponent from "./unseen/Component"
import { InstancedMesh, Mesh, MeshPhysicalMaterial } from "three"
import store from "_store"
import OutroTreeMaterial from "_webgl/materials/outroTree/OutroTreeMaterial"

export default class OutroOneEnvironment extends createComponent() {
	constructor(options = {}) {
		options = mergeDeep({
			name: "Outro One Environment"
		}, options)

		super(options)

		this.meshes = {}
	}

	build() {
		const singleMeshes = ['chateau', 'bg_Hills', 'ground_sculpt']

		for (const key of singleMeshes) {
			this.meshes[key] = this.buildMesh(this.parent.assets.models[key])
			this.add(this.meshes[key])
		}

		const instancedMeshes = ['tree_cypress_LOD2', 'tree_cypress_LOD3', 'sycamore_LOD2']

		for (const key of instancedMeshes) {
			this.meshes[key] = this.buildInstancedMesh(this.parent.assets.models[key])
			this.add(this.meshes[key])
		}
	}

	buildMesh(model) {
		const material = new MeshPhysicalMaterial(model.material)

		material.onBeforeCompile = this.onBeforeCompile.bind(this)

		const mesh = new Mesh(
			model.geometry.clone(),
			material
		)
		mesh.name = `${this.options.name} / ${model.name}`

		mesh.castShadow = true
		mesh.receiveShadow = true

		mesh.position.copy(model.position)
		mesh.scale.copy(model.scale)
		mesh.rotation.copy(model.rotation)

		return mesh
	}

	onBeforeCompile = (shader) => {
		shader.defines.USE_FOG = true
		shader.defines.SOLID_FOG = false

		Object.assign(shader.uniforms, {
			...this.parent.globalUniforms.fog,
			...store.WebGL.globalUniforms
		})

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_pars_vertex>',
			`
                varying vec3 vWorldPosition;
                #include <fog_vert_pars>
            `
		)

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_vertex>',
			`
                vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
                #include <fog_vert>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_pars_fragment>',
			`
                uniform vec2 uResolution;
                varying vec3 vWorldPosition;
                #include <fog_frag_pars>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_fragment>',
			`
                #include <fog_frag>
            `
		)
	}

	buildInstancedMesh(model) {
		const mesh = new InstancedMesh(
			model.geometry.clone(),
			new OutroTreeMaterial({
				uniforms: {
					tDiffuse: { value: model.material.map },
					...this.parent.globalUniforms.fog
				}
			}),
			model.count
		)
		mesh.instanceMatrix = model.instanceMatrix
		mesh.name = `${this.options.name} / ${model.name}`

		mesh.position.copy(model.position)
		mesh.scale.copy(model.scale)
		mesh.rotation.copy(model.rotation)

		return mesh
	}

	addGui() {
		for (const key in this.meshes) {
			store.theatre.helper.autoAddObject(this.meshes[key], this.parent.prettyName)
		}
	}
}
import { debounce, E, qs, RAFCollection } from '_utils'
import store from '_store'

export default class GlobalEvents {
	/**
	 * Global mousemove bus event
	 * @type {string}
	 */
	static MOUSEMOVE = 'global:mousemove'

	/**
	 * Global touch bus event
	 * @type {string}
	 */
	static TOUCHMOVE = 'global:touchmove'

	/**
	 * Global mouse drag bus event
	 * @type {string}
	 */
	static MOUSEDRAG = 'global:mousedrag'

	/**
	 * Global touch drag/move bus event
	 * @type {string}
	 */
	static TOUCHDRAG = 'global:touchdrag'

	/**
	 * Global pointer (touch or mouse) move bus event
	 * @type {string}
	 */
	static POINTERMOVE = `${GlobalEvents.MOUSEMOVE} ${GlobalEvents.TOUCHMOVE}`

	/**
	 * Global pointer (touch or mouse) drag bus event
	 * @type {string}
	 */
	static POINTERDRAG = `${GlobalEvents.TOUCHDRAG} ${GlobalEvents.MOUSEDRAG}`

	/**
	 * Global pointer down bus event
	 * @type {string}
	 */
	static POINTERDOWN = 'global:pointerdown'

	/**
	 * Global pointer up bus event
	 * @type {string}
	 */
	static POINTERUP = 'global:pointerup'

	/**
	 * Global document mouseleave bus event
	 * @type {string}
	 */
	static MOUSELEAVE = 'global:mouseleave'

	/**
	 * Global document mouseenter bus event
	 * @type {string}
	 */
	static MOUSEENTER = 'global:mouseenter'

	/**
	 * Global requestAnimationFrame bus event
	 * @type {string}
	 */
	static RAF = 'global:raf'

	/**
	 * Global resize bus event
	 * @type {string}
	 */
	static RESIZE = 'global:resize'

	/**
	 * Global scroll bus event
	 * @type {string}
	 */
	static SCROLL = 'global:scroll'

	/**
	 * The ID of the last run animation frame
	 * @private
	 * @type {null|number}
	 */
	static currentRafId = null

	/**
	 * List of enabled global events
	 */
	static enabled = {}

	/**
	 * Holds drag related properties
	 * @private
	 * @type {{x: number, px: number, y: number, py: number}}
	 */
	static dragInfo = {
		x: 0,
		y: 0,
		px: 0,
		py: 0
	}

	/**
	 * prevents duplicate mousedown firing on touchstart
	 * @private
	 * @type {boolean}
	 */
	static stopMouseDown = false

	/**
	 * prevents duplicate mouseup firing on touchend
	 * @private
	 * @type {boolean}
	 */
	static stopMouseUp = false

	/**
	 * Enable global:mousemove
	 */
	static enablePointerEvents() {
		if (typeof GlobalEvents.enabled.pointerEvents === 'undefined') {
			GlobalEvents.enabled.pointerEvents = true
			// E.on('mousemove touchmove', window, throttle((e) => GlobalEvents.handleMousemove(e), 16), { passive: true })
			E.on('mousemove touchmove', window, GlobalEvents.handleMousemove, { passive: true })
			E.on('mousedown touchstart', window, GlobalEvents.handleMousedown)
			E.on('mouseup touchend', window, GlobalEvents.handleMouseup)
			E.on('mouseleave', document, GlobalEvents.handleDocumentLeave)
			E.on('mouseenter', document, GlobalEvents.handleDocumentEnter)
		}
	}

	/**
	 * Enable global:resize
	 * @param {boolean|number} tick
	 */
	static enableResize(tick = false) {
		if (typeof GlobalEvents.enabled.resize === 'undefined') {
			GlobalEvents.enabled.resize = true

			if (tick) {
				E.on('resize', window, debounce((e) => GlobalEvents.handleResize(e), tick))
			} else {
				E.on('resize', window, GlobalEvents.handleResize)
			}
		}
	}

	/**
	 * Enable global:raf
	 * @param {?GSAP} gsapInstance
	 */
	static enableRAF(gsapInstance = null) {
		if (typeof GlobalEvents.enabled.raf === 'undefined') {
			GlobalEvents.enabled.raf = true
			store.RAFCollection = new RAFCollection()

			if (gsapInstance) {
				GlobalEvents.currentRafId = null
				gsapInstance.ticker.add(GlobalEvents.handleRaf)
			} else {
				GlobalEvents.currentRafId = window.requestAnimationFrame(GlobalEvents.handleRaf)
			}
		}
	}

	/**
	 * Enable global drag events
	 */
	static enableDrag() {
		if (typeof GlobalEvents.enabled.drag === 'undefined') {
			GlobalEvents.enabled.drag = true
		}
	}

	static enableScroll() {
		let scroller = qs('.scroll-container')
		let ease = 0.075
		let delta = 0

		if (!scroller) {
			scroller = store.html
			ease = 0.05
		}

		let current = scroller.scrollTop

		E.on(GlobalEvents.RAF, () => {
			// only run this callback if there are active listeners
			if (E.hasBus(GlobalEvents.SCROLL)) {
				const now = scroller.scrollTop
				let lerped = delta + ((current - now) - delta) * ease

				if (lerped < 0.0005 && lerped > -0.0005) {
					lerped = 0
				}

				E.emit(GlobalEvents.SCROLL, lerped)

				delta = lerped
				current = now
			}
		})
	}

	/**
	 * Emit the global:mousemove event, and update store.pointer
	 * @private
	 * @param {MouseEvent|TouchEvent} e
	 */
	static handleMousemove(e) {
		store.pointer.x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX
		store.pointer.y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY
		store.pointer.gl.set(store.pointer.x - store.window.w / 2, -store.pointer.y + store.window.h / 2)
		store.pointer.glNormalized.set((store.pointer.x / store.window.w) * 2 - 1, -(store.pointer.y / store.window.h) * 2 + 1)
		store.pointer.glScreenSpace.set(store.pointer.x / store.window.w, 1 - store.pointer.y / store.window.h)

		if (!e.changedTouches) {
			E.emit(GlobalEvents.MOUSEMOVE, e)
		} else {
			E.emit(GlobalEvents.TOUCHMOVE, e)
		}

		if (store.pointer.isDragging) {
			const args = {
				deltaX: store.pointer.x - GlobalEvents.dragInfo.px,
				deltaY: store.pointer.y - GlobalEvents.dragInfo.py,
				startX: GlobalEvents.dragInfo.x,
				startY: GlobalEvents.dragInfo.y,
				totalX: store.pointer.x - GlobalEvents.dragInfo.x,
				totalY: store.pointer.y - GlobalEvents.dragInfo.y
			}

			E.emit(e.changedTouches ? GlobalEvents.TOUCHDRAG : GlobalEvents.MOUSEDRAG, e, args)

			GlobalEvents.dragInfo.px = store.pointer.x
			GlobalEvents.dragInfo.py = store.pointer.y
		}
	}

	/**
	 * Emit the global:pointerdown event
	 * @private
	 * @param {MouseEvent|TouchEvent} e
	 */
	static handleMousedown(e) {
		if (e.type === 'touchstart') {
			this.stopMouseDown = true
		} else {
			if (this.stopMouseDown) {
				this.stopMouseDown = false
				return
			}
			this.stopMouseDown = false
		}

		GlobalEvents.handleMousemove(e)

		if (GlobalEvents.enabled.drag) {
			store.pointer.isDragging = true
			GlobalEvents.dragInfo.x = GlobalEvents.dragInfo.px = store.pointer.x
			GlobalEvents.dragInfo.y = GlobalEvents.dragInfo.py = store.pointer.y
		}

		E.emit(GlobalEvents.POINTERDOWN, e)
	}

	/**
	 * Emit the global:pointerup event
	 * @private
	 * @param {MouseEvent|TouchEvent} e
	 */
	static handleMouseup(e) {
		store.pointer.isDragging = false

		if (e.type === 'touchend') {
			this.stopMouseUp = true
		} else {
			if (this.stopMouseUp) {
				this.stopMouseUp = false
				return
			}
			this.stopMouseUp = false
		}

		// enable all pointer downs again
		this.stopMouseDown = false

		GlobalEvents.handleMousemove(e)
		GlobalEvents.dragInfo = { x: 0, y: 0, px: 0, py: 0 }

		E.emit(GlobalEvents.POINTERUP, e)
	}

	/**
	 * Emit the global:mouseleave event
	 * @param {MouseEvent} e
	 */
	static handleDocumentLeave(e) {
		store.pointer.x = e.clientX
		store.pointer.y = e.clientY

		E.emit(GlobalEvents.MOUSELEAVE, e)
	}

	/**
	 * Emit the global:mouseenter event
	 * @param {MouseEvent} e
	 */
	static handleDocumentEnter(e) {
		store.pointer.x = e.clientX
		store.pointer.y = e.clientY

		E.emit(GlobalEvents.MOUSEENTER, e)
	}

	/**
	 * Emit global:resize event
	 * @private
	 * @param {Event} e
	 */
	static handleResize(e) {
		store.window.w = window.innerWidth
		store.window.h = window.innerHeight

		E.emit(GlobalEvents.RESIZE, e)
	}

	/**
	 * Emit the global:raf event
	 * @private
	 * @param {number} time
	 */
	static handleRaf(time) {
		store.Gui?.perfBegin()

		E.emit(GlobalEvents.RAF, time)

		if (GlobalEvents.currentRafId !== null) {
			GlobalEvents.currentRafId = window.requestAnimationFrame(GlobalEvents.handleRaf)
		}

		store.Gui?.perfEnd(GlobalEvents.currentRafId === null ? time * 1000 : time)
	}
}

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { CubeTextureLoader, TextureLoader } from 'three'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
// import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader'
import store from '_store'
import AssetLoader from '_utils/AssetLoader'
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader'

const ktxs = import.meta.glob('/public/webgl/textures/*.ktx2', { as: 'url' })

/**
*   Add any promises that need to be resolved before showing
*   the page by using the add( promise ) method.
*/
export default class WebGLAssetLoader extends AssetLoader {
	constructor(progressEventName) {
		super(progressEventName)

		this.gltfs = {}
		this.textures = {}
		this.ktxTextures = {}
		this.envMaps = {}
		this.hdrTextures = {}

		this.availableKtxFiles = []

		for (const key in ktxs) {
			this.availableKtxFiles.push(key.split('\\').pop().split('/').pop().split('.')[0])
		}

		this.ktxLoader = new KTX2Loader()
		this.ktxLoader.setTranscoderPath(`${store.publicUrl}basis/`)

		this.textureLoader = new TextureLoader()
		this.gltfLoader = new GLTFLoader()
		this.dracoLoader = new DRACOLoader()
		this.dracoLoader.setDecoderPath(`${store.publicUrl}draco/`)
		this.gltfLoader.setDRACOLoader(this.dracoLoader)
		this.cubeTextureLoader = new CubeTextureLoader()
		this.hdrTextureLoader = new RGBELoader()
	}

	loadGltf = (url) => {
		if (!this.gltfs[url]) {
			this.gltfs[url] = this.add(new Promise((resolve, reject) => {
				this.gltfLoader.load(url, gltf => {
					resolve(gltf)
				}, undefined, error => {
					reject(new Error('glTF not loaded', { cause: { error, url } }))
				})
			}))
		}

		return this.gltfs[url]
	}

	loadTexture = (url, options, force = false) => {
		// check if a KTX version of the texture is available and load that instead
		if (this.ktxLoader && !force && this.availableKtxFiles.includes(url.split('\\').pop().split('/').pop().split('.')[0])) {
			return this.loadKtxTexture(url.substr(0, url.lastIndexOf('.')) + '.ktx2', options)
		}

		if (!this.textures[url]) {
			this.textures[url] = this.add(new Promise((resolve, reject) => {
				this.textureLoader.load(url, texture => {
					resolve(store.WebGL.generateTexture(texture, options))
				}, undefined, error => {
					reject(new Error('Texture not loaded', { cause: { error, url } }))
				})
			}))
		}

		return this.textures[url]
	}

	loadHDR = (url) => {
		if (!this.hdrTextures[url]) {
			this.hdrTextures[url] = this.add(new Promise((resolve, reject) => {
				this.hdrTextureLoader.load(url, texture => {
					resolve(texture)
				}, undefined, error => {
					console.log(error)
					reject(new Error('Texture not loaded', { cause: { error, url } }))
				})
			}))
		}

		return this.hdrTextures[url]
	}

	loadEnvMap = (urls = []) => {
		if (!this.envMaps[urls[0]]) {
			this.envMaps[urls[0]] = this.add(new Promise((resolve, reject) => {
				this.cubeTextureLoader.load(urls, texture => {
					resolve(texture)
				}, undefined, reject)
			}))
		}

		return this.envMaps[urls[0]]
	}

	loadKtxTexture(url, options) {
		if (!this.ktxTextures[url]) {
			this.ktxTextures[url] = this.add(new Promise((resolve, reject) => {
				this.ktxLoader.load(url, texture => {
					// texture.generateMipmaps = true
					resolve(store.WebGL.generateTexture(texture, options))
				}, undefined, error => {
					console.error(error, error?.name, error?.stack, error?.message, error?.cause, url)
					reject(error)
				})
			}))
		}

		return this.ktxTextures[url]
	}
}

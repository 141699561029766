import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { Color, DoubleSide, Texture } from 'three'

export default class VinesMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tBark: { value: new Texture() },
				tLeavesLod0: { value: new Texture() },
				tLeavesLod2: { value: new Texture() },
				uWind: { value: 0.1, gui: { min: 0, max: 1, step: 0.001 } },
				uColor: { value: new Color(0xffd2a6) },
				uSaturation: { value: 0, gui: { min: -1, max: 1, step: 0.001 } },
				uBrightness: { value: 0, gui: { min: -1, max: 1, step: 0.001 } },
				uHue: { value: 0, gui: { min: -1, max: 1, step: 0.001 } }
			},
			defines: {
				USE_FOG: true,
				SOLID_FOG: false,
				IS_BATCHED: false // A new define becasue BatchedMesh only sets the define in the vertex shader for some reason and we need it in the fragment too
			},
			transparent: true,
			side: DoubleSide
		}, options)

		super(options)
	}
}

import { mergeDeep } from "_utils/index"
import createComponent from "./unseen/Component"
import { InstancedMesh, Mesh, Object3D, RepeatWrapping, SRGBColorSpace } from "three"
import store from "_store"
import VinesMaterial from "_webgl/materials/vinesMaterial/VinesMaterial"

export default class OutroTwoVines extends createComponent() {
	constructor(options = {}) {
		options = mergeDeep({
			name: "Outro Two Vines"
		}, options)

		super(options)

		this.meshes = {}

		this._dummy = new Object3D()
	}

	build() {
		// Reposition re-used single vines
		const repositionedVinesSingle = ['vine_2_LOD0']

		for (const key of repositionedVinesSingle) {
			this.meshes[key] = this.buildMesh(this.parent.assets.models[key], this.parent.assets.models[`${key}-data`])
			this.add(this.meshes[key])
		}

		// Reposition re-used instanced vines
		const repositionedInstancedMeshes = ['vine_1_LOD0']

		for (const key of repositionedInstancedMeshes) {
			this.meshes[key] = this.buildReusedInstancedMesh(this.parent.assets.models[key], key)
			this.add(this.meshes[key])
		}
	}

	buildMesh(model, data = null) {
		const mesh = new Mesh(
			model.geometry.clone(),
			new VinesMaterial({
				uniforms: {
					tBark: { value: this.assets.textures.vines.bark },
					tLeaves: { value: this.assets.textures.vines['leaves-lod0'] },
					...this.parent.globalUniforms.fog
				}
			})
		)
		mesh.name = `${this.options.name} / ${model.name}`

		if (data) {
			mesh.position.copy(data.position)
			mesh.scale.copy(data.scale)
			mesh.rotation.copy(data.rotation)
		} else {
			mesh.position.copy(model.position)
			mesh.scale.copy(model.scale)
			mesh.rotation.copy(model.rotation)
		}

		return mesh
	}

	buildReusedInstancedMesh(model, name) {
		const dataKeys = Object.keys(this.parent.assets.models).filter((key) => key.startsWith(`${name}-data`))
		const count = dataKeys.length

		const mesh = new InstancedMesh(
			model.geometry.clone(),
			new VinesMaterial({
				uniforms: {
					tBark: { value: this.assets.textures.vines.bark },
					tLeaves: { value: this.assets.textures.vines['leaves-lod0'] },
					...this.parent.globalUniforms.fog
				}
			}),
			count
		)
		mesh.name = `${this.options.name} / ${model.name}`

		for (let i = 0; i < count; i++) {
			const dataObj = this.parent.assets.models[dataKeys[i]]
			this._dummy.position.copy(dataObj.position)
			this._dummy.scale.copy(dataObj.scale)
			this._dummy.rotation.copy(dataObj.rotation)
			this._dummy.updateMatrix()
			mesh.setMatrixAt(i, this._dummy.matrix)
		}

		return mesh
	}

	load() {
		this.assets.textures.vines = {}
		const textures = ['bark', 'leaves-lod0', 'leaves-lod2']

		textures.forEach(textureName => {
			store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/vine-${textureName}.png`, { colorSpace: SRGBColorSpace, wrapping: RepeatWrapping, flipY: false }).then(texture => {
				this.assets.textures.vines[textureName] = texture
			})
		})
	}

	addGui() {
		for (const key in this.meshes) {
			store.theatre.helper.autoAddObject(this.meshes[key], this.parent.prettyName)
		}
	}
}
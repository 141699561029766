import store from '_store'
import createComponent from './unseen/Component'
import { Mesh } from 'three'
import RootMaterial from '_webgl/materials/rootMaterial/RootMaterial'

/**
 * A 3D tube mesh representing roots. Reveals itself over time. Has lighting and shadowing.
 */
export default class Roots extends createComponent() {
	constructor() {
		super({
			name: 'Roots'
		})
	}

	build() {
		this.mesh = new Mesh(this.assets.models.root.geometry, new RootMaterial({
			uniforms: {
				...this.parent.globalUniforms.components,
				...this.parent.globalUniforms.sun,
				...this.parent.globalUniforms.shadow
			}
		}))
		this.mesh.name = this.name + ' / Root'
		this.add(this.mesh)
	}

	load() {
		store.AssetLoader.loadGltf(`${store.publicUrl}webgl/models/roots.glb`).then(gltf => {
			this.assets.models.root = gltf.scene.getObjectByName('roots_1')
		})
	}

	addGui() {
		store.theatre.helper.autoAddObject(this.mesh, this.parent.prettyName, { exclude: [...Object.keys(this.parent.globalUniforms.components), ...Object.keys(this.parent.globalUniforms.sun)] })
	}
}
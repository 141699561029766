#include <default_pars>
#include <packing>

#ifdef DYNAMIC_SHADOWS
    #include <shadowmap_pars_vertex> 
#endif

attribute float _random1;
attribute float _random2;
attribute float _dist;

varying vec2 vUv;
uniform float uTime;

varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;
varying vec2 vScreenSpace;
varying vec3 vPosition;

varying float vBranchRandom1;
varying float vBranchRandom2;
varying float vProgress;

#ifdef DEPTH_PACKING
    varying vec2 vHighPrecisionZW;
#endif

float rand(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

void main() {

    vUv = uv;
    vProgress = _dist;
    vBranchRandom1 = _random1;
    vBranchRandom2 = _random2;

    vec4 transformedPosition = vec4( position, 1.0 );

    #ifdef USE_INSTANCING
        transformedPosition = instanceMatrix * transformedPosition;
    #endif

    vPosition = transformedPosition.xyz;

    vec4 worldPosition = modelMatrix * transformedPosition;
    vWorldPosition = worldPosition;

    mat3 normalMatrixInverse = inverse(mat3(normalMatrix));
    vNormalMatrix = normalMatrix;
    vNormalMatrixInverse = normalMatrixInverse;
    vec3 transformedNormal = normal;
    vec3 transformedNormalInverse = normal;

    #ifdef USE_INSTANCING
        mat3 m = mat3( instanceMatrix );

        transformedNormal /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormal = m * transformedNormal;

        transformedNormalInverse /= vec3( dot( m[ 0 ], m[ 0 ] ), dot( m[ 1 ], m[ 1 ] ), dot( m[ 2 ], m[ 2 ] ) );
        transformedNormalInverse = m * transformedNormalInverse;
    #endif

    transformedNormal = normalMatrix * normal;
    transformedNormalInverse = normalMatrixInverse * normal;

    vNormal = normalize(transformedNormal);
    vNormalInverse = normalize(transformedNormalInverse);

    vNormalMatrixInverse = normalMatrixInverse;
    vNormalMatrix = mat3(normalMatrix);

    #ifdef DYNAMIC_SHADOWS
        #include <shadowmap_vertex>
    #endif

    vec4 mvPosition = modelViewMatrix * transformedPosition;

    gl_Position = projectionMatrix * mvPosition;

    vViewPosition = -mvPosition.xyz;

    #ifdef DEPTH_PACKING
        vHighPrecisionZW = gl_Position.zw;
    #endif

    vScreenSpace = gl_Position.xy / gl_Position.w * 0.5 + vec2(0.5);
}
import AmbientParticles from '_webgl/components/AmbientParticles'
import BaseScene from './BaseScene'
import { AmbientLight, CameraHelper, Color, DirectionalLight, DirectionalLightHelper, LinearFilter, SRGBColorSpace, Vector3, WebGLRenderTarget } from 'three'
import store from '_store'
import { types } from '@theatre/core'
import BackgroundSphere from '_webgl/components/BackgroundSphere'
import { E } from '_utils/index'
import OutroVines from '_webgl/components/OutroVines'
import OutroOneEnvironment from '_webgl/components/OutroOneEnvironment'

export default class OutroOneScene extends BaseScene {
	constructor() {
		super('outro-one', {
			fluidEnabled: true,
			autoBuildObjects: false,
			endScreenVisibleFromEnd: 1,
			audioFiles: ['outro']
		})

		this.endScreenVisible = false

		Object.assign(this.cameraLayers, {
			particles: 1,
			renderBackground: 2
		})

		Object.assign(this.globalUniforms, {
			shadow: {
				uShadowColor: { value: new Color(0x1d1812) },
				uShadowIntensity: { value: 0.52 }
			},
			sun: {
				uLightDirection: { value: new Vector3() },
				uLightPosition: { value: new Vector3() },
				uLightColor: { value: new Color() }
			},
			fog: {
				uEnableFog: { value: true },
				uDebugFog: { value: false },
				uFogNear_D: { value: 1 },
				uFogFar_D: { value: 60 },
				uFogStrength_D: { value: 1. },
				uFogNear_H: { value: -1.5 },
				uFogFar_H: { value: -3.0 },
				uFogStrength_H: { value: 1.0 },
				uSceneFogMix: { value: 1 },
				uSolidFogColor: { value: new Color(0xa28165) },
				tBackground: { value: null }
			}

		})

		const shadowTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Shadow', {
			color: store.theatre.helper.parseColor(this.globalUniforms.shadow.uShadowColor.value, 'shadow color'),
			intensity: types.number(this.globalUniforms.shadow.uShadowIntensity.value, { label: 'shadow intensity', range: [0, 1], nudgeMultiplier: 0.01 })
		})

		shadowTheatreObject.onValuesChange(values => {
			this.globalUniforms.shadow.uShadowColor.value.copy(values.color)
			this.globalUniforms.shadow.uShadowIntensity.value = values.intensity
		})

		const fogConfig = {}

		const fogUniformCallbacks = store.theatre.helper.autoAddUniforms(fogConfig, this.globalUniforms.fog, [], [], "")
		const fogTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Fog', fogConfig)
		fogTheatreObject.onValuesChange(values => {
			fogUniformCallbacks.forEach(callback => callback(null, values))
		})

		this.background = new Color(0xa28165)

		const backgroundTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Background', {
			color: store.theatre.helper.parseColor(this.background, 'background color')
		})

		backgroundTheatreObject.onValuesChange(values => {
			this.background.copy(values.color)
		})

		Object.assign(this.components, {
			background: new BackgroundSphere({
				scene: this,
				position: new Vector3(0, 0, -42),
				scale: new Vector3(100, 100, 100),
				materialOpts: {
					uniforms: {
						uBaseColor1: { value: new Color(0xf3d6aa) },
						uBaseColor2: { value: new Color(0xcf9b6c) },
						uBaseColor3: { value: new Color(0x010101) },
						uStep1: { value: 0.16, gui: { min: 0, max: 1, step: 0.001 } },
						uStep2: { value: 0.58, gui: { min: 0, max: 1, step: 0.001 } },
						uStep3: { value: 0.98, gui: { min: 0, max: 1, step: 0.001 } },

						uBrushScale: { value: 4.4, gui: { min: 0, max: 10, step: 0.001 } },
						uBrushMinEdge: { value: 0.5, gui: { min: 0, max: 1, step: 0.001 } },
						uBrushMaxEdge: { value: 1.0, gui: { min: 0, max: 1, step: 0.001 } },
						uBrushBlend: { value: 1.0, gui: { min: 0, max: 1, step: 0.001 } },
						uBrushOffset: { value: new Vector3(0.0, 0.0, 0.0), gui: { min: 0, max: 1, step: 0.001 } },
						uBrushRotation: { value: 0.0, gui: { min: 0, max: Math.PI * 2, step: 0.001 } }
					}
				}
			}),
			ambientParticles: new AmbientParticles({
				bounds: new Vector3(20, 20, 20),
				count: 3000
			}),
			vines: new OutroVines(),
			environment: new OutroOneEnvironment()
		})

		this.ignoreDataObjects.push('floor')
	}

	build() {
		this.buildSun()
		this.buildBackgroundRenderTarget()
		super.build()
	}

	buildBackgroundRenderTarget() {
		this.backgroundRenderTarget = new WebGLRenderTarget(store.window.w, store.window.h, { colorSpace: SRGBColorSpace })
		this.backgroundRenderTarget.texture.minFilter = LinearFilter
		this.backgroundRenderTarget.texture.magFilter = LinearFilter
		this.backgroundRenderTarget.texture.generateMipmaps = false
		this.backgroundRenderTarget.stencilBuffer = false
		this.backgroundRenderTarget.depthBuffer = false

		this.globalUniforms.fog.tBackground.value = this.backgroundRenderTarget.texture
	}

	buildSun() {
		this.ambientLight = new AmbientLight(0x1d1d1d, 1.0)
		this.add(this.ambientLight)

		this.dirLight2 = new DirectionalLight(0xffe1c8, 3.5)
		this.dirLight2.position.set(6.7, 3.8, -1.4)
		this.dirLight2.target.position.set(0, 0, 0)
		this.add(this.dirLight2.target)
		this.add(this.dirLight2)

		this.dirLight2Helper = new DirectionalLightHelper(this.dirLight2)
		this.dirLight2Helper.visible = false
		this.add(this.dirLight2Helper)

		const dirLight2TheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Directional Light 2', {
			position: types.compound({
				x: types.number(this.dirLight2.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.dirLight2.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.dirLight2.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.dirLight2.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.dirLight2.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.dirLight2.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.dirLight2.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.dirLight2.color)
		})

		dirLight2TheatreObject.onValuesChange(values => {
			this.dirLight2.position.set(values.position.x, values.position.y, values.position.z)
			this.dirLight2.target.position.set(values.target.x, values.target.y, values.target.z)
			this.dirLight2.intensity = values.intensity
			this.dirLight2.color.copy(values.color)
			this.dirLight2Helper.update()
		}, store.theatre.rafDriver)

		const dirLight2StudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Directional Light 2', {
			visible: types.boolean(this.dirLight2Helper.visible, { label: 'helper' })
		})

		dirLight2StudioObject.onValuesChange(values => {
			this.dirLight2Helper.visible = values.visible
			this.dirLight2Helper.update()
		}, store.theatre.rafDriver)

		this.sun = new DirectionalLight(0xffffff, 1.0)

		this.sun.shadow.autoUpdate = false
		this.sun.position.set(6.7, 13.5, 13.7)
		this.sun.target.position.set(0, 0, 5.8)
		this.sun.castShadow = true
		this.sun.shadow.mapSize.width = store.mq.touch.matches ? 1024 : 2048
		this.sun.shadow.mapSize.height = store.mq.touch.matches ? 1024 : 2048

		this.sun.shadow.camera.near = 12.7
		this.sun.shadow.camera.far = 43.4
		this.sun.shadow.camera.left = -4.40
		this.sun.shadow.camera.right = 50.30
		this.sun.shadow.camera.top = 43
		this.sun.shadow.camera.bottom = -0.2
		this.sun.shadow.blurSamples = 6

		this.sun.shadow.radius = 4
		this.sun.shadow.bias = -0.00039
		this.sun.shadow.normalBias = 0.00073

		this.add(this.sun)
		this.add(this.sun.target)

		this.sunHelper = new DirectionalLightHelper(this.sun)
		this.sunHelper.visible = false
		this.add(this.sunHelper)

		this.sunShadowHelper = new CameraHelper(this.sun.shadow.camera)
		this.sunShadowHelper.visible = false
		this.add(this.sunShadowHelper)

		const sunStudioObject = store.theatre.helper.addStudioSheetObject(this.prettyName, 'Sun', {
			visible: types.boolean(this.sunHelper.visible, { label: 'sun helper' }),
			shadowHelper: types.boolean(this.sunShadowHelper.visible, { label: 'shadow helper' })
		})

		sunStudioObject.onValuesChange(values => {
			this.sunHelper.visible = values.visible
			this.sunShadowHelper.visible = values.shadowHelper
			this.sunHelper.update()
			this.sunShadowHelper.update()
		}, store.theatre.rafDriver)

		const sunTheatreObject = store.theatre.helper.addSheetObject(this.prettyName, 'Sun', {
			position: types.compound({
				x: types.number(this.sun.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.position.z, { nudgeMultiplier: 0.1 })
			}),
			target: types.compound({
				x: types.number(this.sun.target.position.x, { nudgeMultiplier: 0.1 }),
				y: types.number(this.sun.target.position.y, { nudgeMultiplier: 0.1 }),
				z: types.number(this.sun.target.position.z, { nudgeMultiplier: 0.1 })
			}),
			intensity: types.number(this.sun.intensity, { nudgeMultiplier: 0.1 }),
			color: store.theatre.helper.parseColor(this.sun.color),
			ambientColor: store.theatre.helper.parseColor(this.ambientLight.color),
			ambientIntensity: types.number(this.ambientLight.intensity, { nudgeMultiplier: 0.1 }),
			shadowCamera: types.compound({
				near: types.number(this.sun.shadow.camera.near, { nudgeMultiplier: 0.1 }),
				far: types.number(this.sun.shadow.camera.far, { nudgeMultiplier: 0.1 }),
				left: types.number(this.sun.shadow.camera.left, { nudgeMultiplier: 0.1 }),
				right: types.number(this.sun.shadow.camera.right, { nudgeMultiplier: 0.1 }),
				top: types.number(this.sun.shadow.camera.top, { nudgeMultiplier: 0.1 }),
				bottom: types.number(this.sun.shadow.camera.bottom, { nudgeMultiplier: 0.1 }),
				bias: types.number(this.sun.shadow.bias, { nudgeMultiplier: 0.0001 }),
				normalBias: types.number(this.sun.shadow.normalBias, { nudgeMultiplier: 0.0001 }),
				blurSamples: types.number(this.sun.shadow.blurSamples, { nudgeMultiplier: 1 })
			}),
			shadowRadius: types.number(this.sun.shadow.radius, { nudgeMultiplier: 0.1 })
		})

		sunTheatreObject.onValuesChange(values => {
			this.sun.position.set(values.position.x, values.position.y, values.position.z)
			this.sun.target.position.set(values.target.x, values.target.y, values.target.z)
			this.sun.intensity = values.intensity
			this.sun.color.copy(values.color)

			this.sun.shadow.camera.near = values.shadowCamera.near
			this.sun.shadow.camera.far = values.shadowCamera.far
			this.sun.shadow.camera.left = values.shadowCamera.left
			this.sun.shadow.camera.right = values.shadowCamera.right
			this.sun.shadow.camera.top = values.shadowCamera.top
			this.sun.shadow.camera.bottom = values.shadowCamera.bottom
			this.sun.shadow.bias = values.shadowCamera.bias
			this.sun.shadow.normalBias = values.shadowCamera.normalBias
			this.sun.shadow.blurSamples = values.shadowCamera.blurSamples
			this.sun.shadow.radius = values.shadowRadius

			this.sun.shadow.camera.updateProjectionMatrix()
			this.sun.shadow.needsUpdate = true
			store.WebGL.renderer.shadowMap.needsUpdate = true
			this.sunHelper.update()
			this.sunShadowHelper.update()

			this.ambientLight.color.copy(values.ambientColor)
			this.ambientLight.intensity = values.ambientIntensity
		}, store.theatre.rafDriver)
	}

	start() {
		super.start()
		E.emit('AWorldApart:outroStart')

		store.WebGL.renderer.shadowMap.enabled = true

		this.prevShadowAutoUpdate = store.WebGL.renderer.shadowMap.autoUpdate
		store.WebGL.renderer.shadowMap.autoUpdate = false

		this.sun.shadow.needsUpdate = true
		store.WebGL.renderer.shadowMap.needsUpdate = true
	}

	stop() {
		super.stop()

		this.endScreenVisible = false

		store.WebGL.renderer.shadowMap.autoUpdate = this.prevShadowAutoUpdate
		store.WebGL.renderer.shadowMap.enabled = false
	}

	onTheatreSequenceUpdate(position) {
		super.onTheatreSequenceUpdate(position)

		// check if we need to show the next chapter title
		if (!this.isSwitchingChapter && !this.endScreenVisible && position >= this.theatreSequenceLength - this.options.endScreenVisibleFromEnd) {
			E.emit('AWorldApart:outroEnd')
			this.endScreenVisible = true
		}
	}

	animate() {
		super.animate()

		this.renderBackgroundTexture()

		this.upateLightUniforms()
	}

	addPostFXToTheatre() {
		const sheetObject = super.addPostFXToTheatre()

		sheetObject.initialValue = {
			vignette: {
				strength: 0.943,
				outerStrength: 0.72,
				innerStrength: 0.16
			}
		}
	}

	renderBackgroundTexture() {
		// hide all objects except those on the background layer
		if (this.cameraLayers.renderBackground) {
			this.activeCamera.layers.disableAll()
			this.activeCamera.layers.enable(this.cameraLayers.renderBackground)
		}

		this.background = null

		// update texture
		store.WebGL.renderer.setRenderTarget(this.backgroundRenderTarget)
		store.WebGL.renderer.clear()
		store.WebGL.renderer.render(this, this.activeCamera)
		store.WebGL.renderer.setRenderTarget(null)

		this.background = this.backgroundRenderTarget.texture

		// enable the main layer again
		if (this.cameraLayers.renderBackground) {
			this.activeCamera.layers.disable(this.cameraLayers.renderBackground)
			this.activeCamera.layers.enable(0)
			this.activeCamera.layers.enable(this.cameraLayers.particles)
		}
	}

	onSceneResize() {
		this.backgroundRenderTarget?.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
	}

	upateLightUniforms() {
		this.globalUniforms.sun.uLightPosition.value.copy(this.sun.position)

		this.globalUniforms.sun.uLightDirection.value.copy(this.sun.position)
		this.globalUniforms.sun.uLightDirection.value.sub(this.sun.target.position)
		this.globalUniforms.sun.uLightDirection.value.transformDirection(this.activeCamera.matrixWorldInverse)

		this.globalUniforms.sun.uLightColor.value.copy(this.sun.color)
	}

	load() {
		super.load()

		store.AssetLoader.loadGltf(`${store.publicUrl}webgl/models/outro-s1-scene.glb`).then(gltf => {
			for (let i = 0; i < gltf.scene.children.length; i++) {
				const child = gltf.scene.children[i]
				this.assets.models[child.name] = child
			}
		})
	}
}

import { types } from '@theatre/core'
import store from '_store'
import { AdditiveBlending, BackSide, CustomBlending, DataTexture, DoubleSide, EquirectangularReflectionMapping, FrontSide, MultiplyBlending, NoBlending, NormalBlending, RepeatWrapping, SubtractiveBlending } from 'three'

export default class TheatreHelper {
	/**
	 * Adds a standard Sheet Object to Theatre and saves a reference for use later.
	 *
	 * @param {string} sheetName - The name of the sheet.
	 * @param {string} objectName - The name of the object.
	 * @param {object} config - The configuration for the sheet object.
	 * @param {import('three').Object3D} originalObject - The original object to save a reference to.
	 * @returns {import('@theatre/core').ISheetObject} - The created sheet object.
	 */
	addSheetObject(sheetName, objectName, config, originalObject, reconfigure = false) {
		if (!store.theatre.objects[sheetName]) {
			store.theatre.objects[sheetName] = {}
		}

		const _originalConfig = { ...config }
		const sheetObject = store.theatre.sheets[sheetName].object(objectName, config, { reconfigure })
		sheetObject._originalConfig = _originalConfig

		// create array to store all future onValuesChange callbacks
		sheetObject._onValuesChangeCallbacks = []

		// add a wrapper around onValuesChange to store the callback and allow for unsubscribing in the future
		const onValuesChange = (cb, rafDriver = store.theatre.rafDriver) => {
			const unsubscribe = sheetObject.onValuesChange(cb, rafDriver)
			sheetObject._onValuesChangeCallbacks.push(unsubscribe)
			return unsubscribe
		}

		store.theatre.objects[sheetName][objectName] = sheetObject

		const returnObject = {
			onValuesChange,
			_originalConfig
		}

		// if an original ThreeJS object is provided, save a reference to it
		if (originalObject) {
			sheetObject._webglObject = originalObject
			originalObject._theatreObject = sheetObject

			returnObject._webglObject = originalObject
		}

		return returnObject
	}

	/**
	 * Adds a Studio Sheet Object to Theatre and saves a reference for use later.
	 *
	 * @param {string} sheetName - The name of the studio sheet.
	 * @param {string} objectName - The name of the object.
	 * @param {object} config - The configuration for the sheet object.
	 * @returns {import('@theatre/core').ISheetObject} - The created sheet object.
	 */
	addStudioSheetObject(sheetName, objectName, config) {
		if (!store.theatre.studio) {
			// return a dummy object if Theatre Studio is not enabled
			return {
				onValuesChange: () => { }
			}
		} else {
			if (!store.theatre.studioObjects[sheetName]) {
				store.theatre.studioObjects[sheetName] = {}
			}

			const object = store.theatre.studioSheets[sheetName].object(objectName, config)
			store.theatre.studioObjects[sheetName][objectName] = object

			return object
		}
	}

	/**
	 * Unsubscribes from all onValuesChange callbacks for a Theatre Sheet Object.
	 *
	 * @param {import('@theatre/core').ISheetObject} sheetObject Theatre Sheet Object
	 * @returns {void}
	 */
	unsubscribeFromAllSheetObjectCallbacks(sheetObject) {
		if (sheetObject._onValuesChangeCallbacks) {
			for (let i = 0; i < sheetObject._onValuesChangeCallbacks.length; i++) {
				sheetObject._onValuesChangeCallbacks[i]()
			}
		}
	}

	/**
	 * Detaches all Theatre Sheet Objects from a Theatre Sheet.
	 * Unsubscribes from all onValuesChange callbacks for each object.
	 *
	 * @param {string} sheetName Theatre Sheet
	 * @returns {void}
	 */
	detachAllSheetObjects(sheetName) {
		if (!store.theatre.objects[sheetName]) {
			return
		}

		for (const key in store.theatre.objects[sheetName]) {
			const sheetObject = store.theatre.objects[sheetName][key]
			this.unsubscribeFromAllSheetObjectCallbacks(sheetObject)
			if (sheetObject._webglObject) {
				sheetObject._webglObject._theatreObject = null
				sheetObject._webglObject = null
			}
			store.theatre.sheets[sheetName].detachObject(key)
			delete store.theatre.objects[sheetName][key]
		}

		delete store.theatre.objects[sheetName]
	}

	/**
	 * Detaches all Studio Sheet Objects from a Studio Sheet.
	 *
	 * @param {string} sheetName Studio Sheet
	 * @returns {void}
	 */
	detachAllStudioSheetObjects(sheetName) {
		if (!store.theatre.studioObjects[sheetName]) {
			return
		}

		for (const key in store.theatre.studioObjects[sheetName]) {
			store.theatre.studioSheets[sheetName].detachObject(key)
			delete store.theatre.studioObjects[sheetName][key]
		}
	}

	/**
	 * Deletes a Theatre Sheet from Theatre and removes all references.
	 * Unsubscribes from all onValuesChange callbacks for the object.
	 *
	 * @param {string} sheetName Theatre Sheet
	 * @returns {void}
	 */
	deleteSheet(sheetName) {
		if (store.theatre.sheets[sheetName]) {
			this.detachAllSheetObjects(sheetName)
			store.theatre.sheets[sheetName]._webglScene = null
			delete store.theatre.sheets[sheetName]
		}
	}

	/**
	 * Adds additional config to an existing Theatre Sheet Object.
	 *
	 * @param {import('@theatre/core').ISheetObject} sheetObject Theatre Sheet Object
	 * @param {Object} config Additional config to add
	 * @returns {import('@theatre/core').ISheetObject} Updated Theatre Sheet Object
	 */
	updateSheetObject(sheetObject, config) {
		if (!sheetObject?._originalConfig) {
			throw new Error('Sheet Object is missing the _originalConfig property. It likely wasn\'t created with TheatreHelper.addSheetObject().')
		}

		// create a new object with the updated config as Theatre doesn't allow updating the object it's proxying directly
		const newConfig = {
			...sheetObject._originalConfig,
			...config
		}

		const newSheetObject = this.addSheetObject(sheetObject.address.sheetId, sheetObject.address.objectKey, newConfig, null, true)

		return newSheetObject
	}

	/**
	 * Automatically add object's position and material options
	 *
	 * @param {import('three').Object3D} object Three.js object
	 * @param {string} sheetName Theatre Sheet
	 * @param {Object} param2 Options
	 * @param {string[]} param2.exclude Exclude these properties
	 * @param {string[]} param2.include Include only these properties
	 * @param {boolean} param2.addDefines Add defines to the material
	 * @returns {import('@theatre/core').ISheetObject} Theatre Sheet Object
	 */
	autoAddObject(object, sheetName, { exclude = [], include = [], addDefines = true, additionalConfig = {} } = {}) {
		if (object === undefined || sheetName === undefined) {
			throw new Error('Object and Theatre Sheet are required.')
		}

		const config = {
			visible: types.boolean(object.visible),
			transforms: {
				position: types.compound({
					x: types.number(object.position.x, { nudgeMultiplier: 0.1 }),
					y: types.number(object.position.y, { nudgeMultiplier: 0.1 }),
					z: types.number(object.position.z, { nudgeMultiplier: 0.1 })
				}),
				rotation: types.compound({
					x: types.number(object.rotation.x, { nudgeMultiplier: 0.1 }),
					y: types.number(object.rotation.y, { nudgeMultiplier: 0.1 }),
					z: types.number(object.rotation.z, { nudgeMultiplier: 0.1 })
				}),
				scale: types.compound({
					x: types.number(object.scale.x, { nudgeMultiplier: 0.1 }),
					y: types.number(object.scale.y, { nudgeMultiplier: 0.1 }),
					z: types.number(object.scale.z, { nudgeMultiplier: 0.1 })
				})
			},
			...additionalConfig
		}

		const updateMaterialFunc = this.autoAddMaterial(config, { material: object.material, exclude, include, addDefines })

		const sheetObject = this.addSheetObject(sheetName, object.name, config, object)

		sheetObject.onValuesChange(values => {
			object.visible = values.visible
			object.position.copy(values.transforms.position)
			object.rotation.set(values.transforms.rotation.x, values.transforms.rotation.y, values.transforms.rotation.z)
			object.scale.copy(values.transforms.scale)
			updateMaterialFunc(object, values)
		}, store.theatre.rafDriver)

		return sheetObject
	}

	autoAddMaterial(config, { material, options = true, exclude, include, addDefines = true }) {
		if (!material) {
			return () => { }
		}

		if (!config.material) {
			config.material = {}
		}

		if (options) {
			Object.assign(config.material, {
				// wireframe: types.boolean(material.wireframe),
				side: types.stringLiteral(material.side, {
					[FrontSide]: 'front',
					[BackSide]: 'back',
					[DoubleSide]: 'double'
				}, { as: 'menu' }),
				blending: types.stringLiteral(material.blending, {
					[NoBlending]: 'NoBlending',
					[NormalBlending]: 'NormalBlending',
					[AdditiveBlending]: 'AdditiveBlending',
					[SubtractiveBlending]: 'SubtractiveBlending',
					[MultiplyBlending]: 'MultiplyBlending',
					[CustomBlending]: 'CustomBlending'
				}, { as: 'menu' }),
				transparent: types.boolean(material.transparent),
				depthTest: types.boolean(material.depthTest),
				depthWrite: types.boolean(material.depthWrite),
				colorWrite: types.boolean(material.colorWrite)
			})
		}

		const uniformCallbacks = this.autoAddUniforms(config, material.uniforms, exclude, include)
		const materialCallbacks = this.autoAddThreeMaterial(config, material, exclude, include)

		// if (addDefines) {
		// 	for (const key in material.defines) {
		// 		if (key === 'FOG_PMREM' || key.toLowerCase().includes('cubeuv') || (exclude && exclude.includes(key)) || (include && include.length && !include.includes(key))) continue

		// 		if (key.toLowerCase().includes('tonemap')) {
		// 			const toneMappingOptions = {
		// 				LinearToneMapping: 0,
		// 				ReinhardToneMapping: 1,
		// 				OptimizedCineonToneMapping: 2,
		// 				ACESFilmicToneMapping: 3
		// 			}

		// 			folder.addBinding(material.defines, 'USE_TONEMAPPING').on('change', (e) => {
		// 				material.needsUpdate = true
		// 				tonemapping.disabled = !e.value
		// 				tonemappingExp.disabled = !e.value
		// 			})

		// 			const tonemapping = folder.addBinding(
		// 				material.uniforms.uToneMapping, 'value',
		// 				{ label: 'uToneMapping', options: toneMappingOptions, disabled: !material.defines.USE_TONEMAPPING }
		// 			)

		// 			const tonemappingExp = folder.addBinding(material.uniforms.toneMappingExposure, 'value', { label: 'toneMappingExposure', min: 0.0, max: 5.0, step: 0.001, disabled: !material.defines.USE_TONEMAPPING })
		// 		} else {
		// 			folder.addBinding(material.defines, key).on('change', (e) => {
		// 				material.needsUpdate = true
		// 			})
		// 		}
		// 	}
		// }

		return (object, values) => {
			object.material.wireframe = values.material.wireframe
			object.material.side = parseInt(values.material.side)
			object.material.blending = parseInt(values.material.blending)
			object.material.transparent = values.material.transparent
			object.material.depthTest = values.material.depthTest
			object.material.depthWrite = values.material.depthWrite
			object.material.colorWrite = values.material.colorWrite

			for (let i = 0; i < uniformCallbacks.length; i++) {
				uniformCallbacks[i](object, values)
			}

			for (let i = 0; i < materialCallbacks.length; i++) {
				materialCallbacks[i](object, values)
			}
		}
	}

	autoAddThreeMaterial(config, material, exclude = [], include = []) {
		if (!config.material) {
			config.material = {}
		}

		const callbacks = []

		const props = [
			'aoMap',
			'aoMapIntensity',
			'attenuationColor',
			'attenuationDistance',
			'color',
			'bumpMap',
			'bumpScale',
			'displacementBias',
			'displacementMap',
			'displacementScale',
			'emissive',
			'emissiveIntensity',
			'emissiveMap',
			'_dispersion',
			'_anisotropy',
			'_clearcoat',
			'_iridescence',
			'_sheen',
			'iridescenceIOR',
			'clearcoatRoughness',
			'envMap',
			'envMapIntensity',
			'envMapRotation',
			'ior',
			'map',
			'metalness',
			'metalnessMap',
			'normalMap',
			'normalScale',
			'opacity',
			'roughness',
			'roughnessMap',
			'specular',
			'specularColor',
			'specularMap',
			'specularIntensity',
			'specularColorMap',
			'specularIntensityMap',
			'sheenColor',
			'sheenRoughness',
			'thickness',
			'_transmission'
		]

		if (material.type !== 'ShaderMaterial' && material.type !== 'RawShaderMaterial') {
			for (const key in material) {
				if (props.includes(key)) {
					if (material[key] === null || material[key]?.isTexture) {
						config.material[key] = types.image('', { label: key })

						let isFirstLoad = true

						callbacks.push((object, values) => {
							const src = store.theatre.project.getAssetUrl(values.material[key])

							const textureOptions = {
								wrapping: RepeatWrapping
							}

							if (material[key]?.isTexture) {
								Object.assign(textureOptions, {
									minFilter: material[key].minFilter,
									magFilter: material[key].magFilter,
									flipY: material[key].flipY,
									colorSpace: material[key].colorSpace,
									mapping: material[key].mapping
								})
							}

							if (src && material[key]?.source.data.src !== src) {
								const image = new Image()
								image.addEventListener('load', () => {
									material[key] = store.WebGL.generateTexture(image, textureOptions)
									material[key].needsUpdate = true
									material.needsUpdate = true
									isFirstLoad = false
								}, { once: true })
								image.src = src
							} else if (!src && material[key]?.source.data.src !== src && !isFirstLoad && material[key]?.isTexture) {
								material[key] = null
								material.needsUpdate = true
								isFirstLoad = false
							}
						})
					} else {
						if (material[key].isColor) {
							config.material[key] = this.parseColor(material[key], key)
							callbacks.push((object, values) => {
								material[key].copySRGBToLinear(values.material[key])
							})
						} else if (key.includes('_')) {
							const realKey = key.replace('_', '')
							config.material[realKey] = types.number(material[realKey], { label: realKey, nudgeMultiplier: 0.01 })

							callbacks.push((object, values) => {
								if (material[realKey] !== values.material[realKey]) {
									material[realKey] = values.material[realKey]
									material.needsUpdate = true
								}
							})
						} else {
							if (typeof material[key] === 'object') {
								const props = {}
								for (const prop in material[key]) {
									if (Object.hasOwnProperty.call(material[key], prop) && typeof material[key][prop] === 'number') {
										const realProp = prop.replace('_', '')
										props[realProp] = types.number(material[key][realProp], { nudgeMultiplier: 0.01 })
									}
								}
								config.material[key] = types.compound(props, { label: key })

								callbacks.push((object, values) => {
									if (material[key].isEuler) {
										material[key].set(values.material[key].x, values.material[key].y, values.material[key].z)
									} else {
										material[key].copy(values.material[key])
									}
								})
							} else if (typeof material[key] === 'number') {
								config.material[key] = types.number(material[key] === Infinity ? 100000000 : material[key], { label: key, range: [0, Infinity], nudgeMultiplier: 0.01 })

								callbacks.push((object, values) => {
									material[key] = values.material[key]
								})
							} else if (typeof material[key] === 'boolean') {
								config.material[key] = types.boolean(material[key], { label: key })

								callbacks.push((object, values) => {
									material[key] = values.material[key]
								})
							}
						}
					}
				}
			}
		}

		return callbacks
	}

	autoAddThreeMaterialImages(config, material, exclude = [], include = []) {
		const callbacks = []

		for (const key in material) {
			if (exclude.includes(key) || (include.length && !include.includes(key))) continue
			if (key.toLowerCase().slice(-3) !== 'map') continue
			if (!material[key]) continue

			const property = material[key]

			if (material[key].isTexture) {
				config[key] = types.image('', { label: key })
				callbacks.push((object, values) => {
					const src = store.theatre.project.getAssetUrl(values[key])

					const textureOptions = {
						minFilter: property.minFilter,
						magFilter: property.magFilter,
						wrapping: property.wrapS,
						flipY: property.flipY,
						colorSpace: property.colorSpace,
						mapping: property.mapping
					}

					if (src) {
						const image = new Image()
						image.addEventListener('load', () => {
							if (key === 'envMap') {
								// generate a pmrem texture for envMap
								const envMap = store.WebGL.generateTexture(image, textureOptions)
								if (envMap.mapping !== EquirectangularReflectionMapping) {
									envMap.mapping = EquirectangularReflectionMapping
									envMap.needsUpdate = true
								}
								const envMapPMREM = store.WebGL.pmremHandler.fromEquirectangular(envMap)
								material[key] = envMapPMREM
							} else {
								material[key] = store.WebGL.generateTexture(image, textureOptions)
							}

							material[key].needsUpdate = true
						}, { once: true })
						image.src = src
					}
				})
			}
		}

		return callbacks
	}

	autoAddUniforms(config, uniforms, exclude = [], include = [], ignoreIncluding = 'FOG') {
		if (!config.uniforms) {
			config.uniforms = {}
		}

		const callbacks = []

		for (const key in uniforms) {
			if (
				key === 'uTime' || key === 'uResolution' || key === 'uTexelSize' ||
				key.toLowerCase().includes('delta') ||
				exclude.includes(key) ||
				(include.length && !include.includes(key)) ||
				key.toLowerCase().includes('tonemap')
			) continue

			if (ignoreIncluding && key.toUpperCase().includes(ignoreIncluding)) {
				continue
			}

			const uniform = uniforms[key]

			if (uniform.value === null || uniform.value === undefined) {
				continue
			}

			// skip unsupported inputs
			if (uniform.value.isMatrix4 || uniform.value.isRenderTargetTexture || uniform.value.isDepthTexture || uniform.value instanceof DataTexture || uniform.value instanceof Array) {
				continue
			}

			if (uniform.value.isColor) {
				config.uniforms[key] = this.parseColor(uniform.value, key)
				callbacks.push((object, values) => {
					uniform.value.copy(values.uniforms[key])
				})
			} else if (uniform.value.isTexture) {
				config.uniforms[key] = types.image('', { label: key })
				callbacks.push((object, values) => {
					const src = store.theatre.project.getAssetUrl(values.uniforms[key])

					const textureOptions = {
						minFilter: uniform.value.minFilter,
						magFilter: uniform.value.magFilter,
						wrapping: uniform.value.wrapS,
						flipY: uniform.value.flipY,
						colorSpace: uniform.value.colorSpace
					}

					if (src) {
						const image = new Image()
						image.addEventListener('load', () => {
							uniform.value = store.WebGL.generateTexture(image, textureOptions)
							uniform.value.needsUpdate = true
						}, { once: true })
						image.src = src
					}
				})
			} else {
				if (typeof uniform.value === 'object') {
					const props = {}
					for (const prop in uniform.value) {
						if (Object.hasOwnProperty.call(uniform.value, prop)) {
							const opts = this.parseUniformGui(uniform, prop)
							props[prop] = types.number(uniform.value[prop], opts)
						}
					}
					config.uniforms[key] = types.compound(props, { label: key })

					callbacks.push((object, values) => {
						uniform.value.copy(values.uniforms[key])
					})
				} else if (typeof uniform.value === 'number') {
					const opts = this.parseUniformGui(uniform)
					config.uniforms[key] = types.number(uniform.value, { label: key, ...opts })

					callbacks.push((object, values) => {
						uniform.value = values.uniforms[key]
					})
				} else if (typeof uniform.value === 'boolean') {
					config.uniforms[key] = types.boolean(uniform.value, { label: key })

					callbacks.push((object, values) => {
						uniform.value = values.uniforms[key]
					})
				}
			}
		}

		return callbacks
	}

	parseUniformGui(uniform, prop = false) {
		const opts = {}
		if (uniform.gui) {
			if (uniform.gui.min !== undefined || uniform.gui.max !== undefined) {
				opts.range = [-Infinity, Infinity]
				if (uniform.gui.min !== undefined) opts.range[0] = uniform.gui.min
				if (uniform.gui.max !== undefined) opts.range[1] = uniform.gui.max
			}

			if (uniform.gui.step) {
				opts.nudgeMultiplier = uniform.gui.step
			}

			if (prop && uniform.gui[prop]) {
				opts.range = opts.range || [-Infinity, Infinity]
				if (uniform.gui[prop].min !== undefined) opts.range[0] = uniform.gui[prop].min
				if (uniform.gui[prop].max !== undefined) opts.range[1] = uniform.gui[prop].max
			}
		}

		return opts
	}

	/**
	 * Parses a ThreeJS Color object, converts to sRGB color space because Theatre
	 * displays in this format, then returns a Theatre type for use in Theatre Objects.
	 *
	 * @param {Color} color - The Color object to parse.
	 * @param {string} label - The label for the GUI.
	 * @returns {import('@theatre/core').IType} - The parsed Theatre type.
	 */
	parseColor(color, label = false) {
		const opts = {}

		if (label) {
			opts.label = label
		}

		if (!color.isSRGB) {
			color.convertLinearToSRGB()
			color.isSRGB = true
		}

		return types.rgba({ r: color.r, g: color.g, b: color.b, a: 1 }, opts)
	}
}
import { mergeDeep } from "_utils/index"
import createComponent from "./unseen/Component"
import { InstancedMesh, Mesh, MeshPhysicalMaterial, Object3D } from "three"
import store from "_store"
import OutroTreeMaterial from "_webgl/materials/outroTree/OutroTreeMaterial"

export default class OutroTwoEnvironment extends createComponent() {
	constructor(options = {}) {
		options = mergeDeep({
			name: "Outro Two Environment"
		}, options)

		super(options)

		this.meshes = {}

		this._dummy = new Object3D()
	}

	build() {
		// Use the original 'bg_Hills' model as it is in the same position in both outro scenes
		this.meshes.bg_Hills = this.buildMesh(this.parent.assets.models.bg_Hills)
		this.add(this.meshes.bg_Hills)

		// Use the original 'tree_cypress_LOD3' model as it is in the same position in both outro scenes
		this.meshes.tree_cypress_LOD3 = this.buildInstancedMesh(this.parent.assets.models.tree_cypress_LOD3)
		this.add(this.meshes.tree_cypress_LOD3)

		// Load in new ground model
		this.meshes.S2_ground = this.buildMesh(this.parent.assets.models.S2_ground)
		this.add(this.meshes.S2_ground)

		// Load in new branches
		this.meshes.ash_branches = this.buildInstancedMesh(this.parent.assets.models.ash_branches)
		this.add(this.meshes.ash_branches)

		// Reposition re-used single meshes
		const repositionedMeshes = ['chateau', 'sycamore_LOD2']

		for (const key of repositionedMeshes) {
			this.meshes[key] = this.buildMesh(this.parent.assets.models[key], this.parent.assets.models[`${key}-data`], key === 'sycamore_LOD2')
			this.add(this.meshes[key])
		}

		// Reposition re-useed instanced trees
		const repositionedInstancedMeshes = ['tree_cypress_LOD2']

		for (const key of repositionedInstancedMeshes) {
			this.meshes[key] = this.buildReusedInstancedMesh(this.parent.assets.models[key], key)
			this.add(this.meshes[key])
		}
	}

	buildMesh(model, data = null, tree = false) {
		let material
		if (tree) {
			material = new OutroTreeMaterial({
				uniforms: {
					tDiffuse: { value: model.material.map },
					...this.parent.globalUniforms.fog
				}
			})
		} else {
			material = new MeshPhysicalMaterial(model.material)
			material.onBeforeCompile = this.onBeforeCompile.bind(this)
		}

		const mesh = new Mesh(
			model.geometry.clone(),
			material
		)
		mesh.name = `${this.options.name} / ${model.name}`

		mesh.castShadow = true
		mesh.receiveShadow = true

		if (data) {
			mesh.position.copy(data.position)
			mesh.scale.copy(data.scale)
			mesh.rotation.copy(data.rotation)
		} else {
			mesh.position.copy(model.position)
			mesh.scale.copy(model.scale)
			mesh.rotation.copy(model.rotation)
		}

		return mesh
	}

	onBeforeCompile = (shader) => {
		shader.defines.USE_FOG = true
		shader.defines.SOLID_FOG = false

		Object.assign(shader.uniforms, {
			...this.parent.globalUniforms.fog,
			...store.WebGL.globalUniforms
		})

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_pars_vertex>',
			`
                varying vec3 vWorldPosition;
                #include <fog_vert_pars>
            `
		)

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_vertex>',
			`
                vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
                #include <fog_vert>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_pars_fragment>',
			`
                uniform vec2 uResolution;
                varying vec3 vWorldPosition;
                #include <fog_frag_pars>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_fragment>',
			`
                #include <fog_frag>
            `
		)
	}

	buildInstancedMesh(model) {
		const mesh = new InstancedMesh(
			model.geometry.clone(),
			new OutroTreeMaterial({
				uniforms: {
					tDiffuse: { value: model.material.map },
					...this.parent.globalUniforms.fog
				}
			}),
			model.count
		)
		mesh.instanceMatrix = model.instanceMatrix
		mesh.name = `${this.options.name} / ${model.name}`

		mesh.position.copy(model.position)
		mesh.scale.copy(model.scale)
		mesh.rotation.copy(model.rotation)

		return mesh
	}

	buildReusedInstancedMesh(model, name) {
		const dataKeys = Object.keys(this.parent.assets.models).filter((key) => key.startsWith(`${name}-data`))
		const count = dataKeys.length

		const mesh = new InstancedMesh(
			model.geometry.clone(),
			new OutroTreeMaterial({
				uniforms: {
					tDiffuse: { value: model.material.map },
					...this.parent.globalUniforms.fog
				}
			}),
			count
		)
		mesh.name = `${this.options.name} / ${model.name}`

		for (let i = 0; i < count; i++) {
			const dataObj = this.parent.assets.models[dataKeys[i]]
			this._dummy.position.copy(dataObj.position)
			this._dummy.scale.copy(dataObj.scale)
			this._dummy.rotation.copy(dataObj.rotation)
			this._dummy.updateMatrix()
			mesh.setMatrixAt(i, this._dummy.matrix)
		}

		return mesh
	}

	addGui() {
		for (const key in this.meshes) {
			store.theatre.helper.autoAddObject(this.meshes[key], this.parent.prettyName)
		}
	}
}
import { VSMShadowMap } from 'three'
import WebGL from './WebGL'
import IntroScene from './scenes/IntroScene'
import ChapterOneScene from './scenes/ChapterOneScene'
import ChapterTwoOneScene from './scenes/ChapterTwoOneScene'
import ChapterTwoTwoScene from './scenes/ChapterTwoTwoScene'
import ChapterThreeScene from './scenes/ChapterThreeScene'
import ChapterFourOneScene from './scenes/ChapterFourOneScene'
import ChapterFourTwoScene from './scenes/ChapterFourTwoScene'
import OutroOneScene from './scenes/OutroOneScene'
import OutroTwoScene from './scenes/OutroTwoScene'

export default class AWorldApartWebGL extends WebGL {
	constructor(el) {
		super(el, {
			renderer: {
				shadowMap: VSMShadowMap
			},
			scenes: {
				intro: IntroScene,
				'chapter-one': ChapterOneScene,
				'chapter-two-one': ChapterTwoOneScene,
				'chapter-two-two': ChapterTwoTwoScene,
				'chapter-three': ChapterThreeScene,
				'chapter-four-one': ChapterFourOneScene,
				'chapter-four-two': ChapterFourTwoScene,
				'outro-one': OutroOneScene,
				'outro-two': OutroTwoScene
			},
			composerPassOrder: {
				intro: 0,
				'chapter-one': 10,
				'chapter-two-one': 20,
				'chapter-two-two': 30,
				'chapter-three': 40,
				'chapter-four-one': 50,
				'chapter-four-two': 60,
				'outro-one': 70,
				'outro-two': 80
			}
		})
	}

	calculateChapterLengths() {
		const chapters = [
			['intro'],
			['chapter-one'],
			['chapter-two-one', 'chapter-two-two'],
			['chapter-three'],
			['chapter-four-one', 'chapter-four-two', 'outro-one']
		]

		// loop through chapters and set the chapterSequenceLength to the total theatre sequence length of all scenes in that chapter
		for (let i = 0; i < chapters.length; i++) {
			const chapter = chapters[i]
			let chapterSequenceLength = 0
			for (let j = 0; j < chapter.length; j++) {
				const scene = this.scenes[chapter[j]]
				chapterSequenceLength += scene.theatreSequenceLength
			}
			for (let j = 0; j < chapter.length; j++) {
				const scene = this.scenes[chapter[j]]
				scene.chapterSequenceLength = chapterSequenceLength
			}
		}

		// loop through chapters array and set the chapterProgress to the theatre sequence length of the previous scene in that chapter
		for (let i = 0; i < chapters.length; i++) {
			const chapter = chapters[i]
			let previousScene = null
			for (let j = 0; j < chapter.length; j++) {
				const scene = this.scenes[chapter[j]]
				if (previousScene) {
					scene.chapterProgress = previousScene.chapterProgress + previousScene.theatreSequenceLength
				}
				previousScene = scene
			}
		}
	}
}
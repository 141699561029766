import {
	BoxGeometry,
	Euler,
	Mesh,
	MeshNormalMaterial,
	MeshPhysicalMaterial,
	Object3D,
	ShaderMaterial,
	Vector3
} from "three"
import createComponent from "./unseen/Component"
import { mergeDeep } from "_utils/index"
import store from "_store"
import copyObjectDataTransforms from "_utils/functions/copyObjectDataTransforms"
import { types } from '@theatre/core'
import getSize from '_webgl/utils/getSize'

export default class IntroArch extends createComponent(Object3D) {
	/**
	 * @private
	 * @type {Record<string, Mesh | Object3D>}
	 */
	meshes = {}

	/**
	 * @private
	 * @type {Record<string, MeshPhysicalMaterial>}
	 */
	materials = {}

	/**
	 * @private
	 * @type {Mesh}
	 */
	hitbox

	constructor(options = {}) {
		options = mergeDeep({
			visible: true,
			scene: null,
			name: 'Intro Arch',
			scale: new Vector3(1, 1, 1),
			position: new Vector3(0, 0, 0),
			rotation: new Euler(0, 0, 0),
			objectData: {},
			enableFog: false
		}, options)

		super(options)

		if (Object.keys(this.options.objectData).length) {
			copyObjectDataTransforms(this, this.options.objectData)
		} else {
			this.position.copy(this.options.position)
			this.scale.copy(this.options.scale)
			this.rotation.copy(this.options.rotation)
		}

		this.frustumCulled = false
	}

	build() {
		this.options.scene = this.parent

		this.buildMeshes()
		this.buildHitBox()

		// this.geometry = this.assets.models.arch.geometry
		// this.defaultMaterial = new MeshPhysicalMaterial({
		// 	color: 0xffffff,
		// 	// normalMap: this.assets.textures.arch.normal,
		// 	// normalScale: new Vector3(1, -1), // flip on y
		// 	// roughnessMap: this.assets.textures.arch.roughness,
		// 	// map: this.options.name === 'Intro Arch' ? this.assets.textures.arch['base-color'] : null,
		// 	roughness: 0.9
		// })

		// this.material = this.defaultMaterial

		// if (this.options.enableFog) {
		// 	this.defaultMaterial.onBeforeCompile = this.onBeforeCompile.bind(this)
		// }

		this.depthMaterial = new ShaderMaterial({
			vertexShader: `
				#include <common>
				varying float vDepth;

				varying vec2 vHighPrecisionZW;

				void main() {
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
					vDepth = (gl_Position.z / gl_Position.w) * 0.5 + 0.5;

					vHighPrecisionZW = gl_Position.zw;
				}
			`,
			fragmentShader: `
				#include <common>
				#include <packing>
				varying float vDepth;

				varying vec2 vHighPrecisionZW;

				void main() {

					float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        			gl_FragColor = packDepthToRGBA(fragCoordZ);	 // higher precision

					// gl_FragColor = packDepthToRGBA(vDepth);
				}
			`
		})

		this.godraysMaterial = new ShaderMaterial({
			vertexShader: `
				#include <common>

				varying vec2 vUv;

				varying vec3 vNormal;
				varying float vDepth;
				varying vec2 vHighPrecisionZW;

				void main() {
					vUv = uv;

					vNormal = normalize(normalMatrix * normal);

					gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);

					vDepth = (gl_Position.z / gl_Position.w) * 0.5 + 0.5;
					vHighPrecisionZW = gl_Position.zw;
				}
			`,
			fragmentShader: `
				#include <common>
				varying vec2 vUv;

				varying vec3 vNormal;
				varying float vDepth;
				varying vec2 vHighPrecisionZW;

				void main() {
					float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        			pc_fragColor = vec4(vNormal, fragCoordZ); // pack normal depth with higher precision

					// pc_fragColor = vec4(vNormal, vDepth); // pack normal depth
				}
			`,
			uniforms: {}
		})

		if (this.options.scene.cameraLayers.godrays) this.layers.set(this.options.scene.cameraLayers.godrays)
	}

	buildMeshes() {
		const buildMesh = (model) => {
			const material = new MeshPhysicalMaterial(model.material)

			if (this.options.enableFog) {
				material.onBeforeCompile = this.onBeforeCompile.bind(this)
			}

			const mesh = new Mesh(
				model.geometry.clone(),
				material
			)

			mesh.castShadow = true
			mesh.receiveShadow = true

			mesh.position.copy(model.position)
			mesh.scale.copy(model.scale)
			mesh.rotation.copy(model.rotation)

			mesh.layers.set(this.options.scene.cameraLayers.godrays)

			return mesh
		}

		const meshes = ['arch_rusticated', 'walls', 'wroughtIron_007', 'Door', 'Door.001']

		for (let i = 0; i < meshes.length; i++) {
			const name = meshes[i]
			const model = this.assets.models[name]

			if (model.children.length) {
				const mesh = buildMesh(model.children[0])
				this.materials[model.children[0].name] = mesh.material
				this.meshes[model.children[0].name] = mesh

				this.meshes[name] = new Object3D()
				this.meshes[name].position.copy(model.position)
				this.meshes[name].scale.copy(model.scale)
				this.meshes[name].rotation.copy(model.rotation)

				this.meshes[name].add(mesh)

				this.add(this.meshes[name])
			} else {
				const mesh = buildMesh(model)

				this.materials[name] = mesh.material
				this.meshes[name] = mesh

				this.add(this.meshes[name])
			}
		}
	}

	buildHitBox() {
		this.hitbox = new Mesh(
			new BoxGeometry(),
			new MeshNormalMaterial()
		)

		const leftDoorSize = getSize(this.meshes.Door)
		const rightDoorSize = getSize(this.meshes['Door.001'])
		const topDoorSize = getSize(this.meshes.wroughtIron_007)

		this.hitbox.scale.copy(leftDoorSize)
		this.hitbox.scale.x += rightDoorSize.x
		this.hitbox.scale.y += topDoorSize.y

		this.hitbox.position.y = this.hitbox.scale.y / 2

		this.add(this.hitbox)

		this.hitbox.visible = false
	}

	onBeforeCompile = (shader) => {
		shader.defines.USE_FOG = true
		shader.defines.SOLID_FOG = false

		Object.assign(shader.uniforms, {
			...this.parent.globalUniforms.fog,
			...store.WebGL.globalUniforms
		})

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_pars_vertex>',
			`
            varying vec3 vWorldPosition;
            #include <fog_vert_pars>
            `
		)

		shader.vertexShader = shader.vertexShader.replace(
			'#include <fog_vertex>',
			`
            vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
            #include <fog_vert>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_pars_fragment>',
			`
			uniform vec2 uResolution;
            varying vec3 vWorldPosition;
            #include <fog_frag_pars>
            `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <fog_fragment>',
			`
            #include <fog_frag>
            `
		)
	}

	setDepthMaterial() {
		for (let i = 0; i < Object.keys(this.materials).length; i++) {
			const name = Object.keys(this.materials)[i]
			const mesh = this.meshes[name]

			mesh.material = this.depthMaterial
		}
		// this.material = this.depthMaterial
	}

	setDefaultMaterial() {
		for (let i = 0; i < Object.keys(this.materials).length; i++) {
			const name = Object.keys(this.materials)[i]
			const mesh = this.meshes[name]

			mesh.material = this.materials[name]
		}
		// this.material = this.defaultMaterial
	}

	setGodraysMaterial() {
		for (let i = 0; i < Object.keys(this.materials).length; i++) {
			const name = Object.keys(this.materials)[i]
			const mesh = this.meshes[name]

			mesh.material = this.godraysMaterial
		}
		// this.material = this.godraysMaterial
	}

	destroy() {
		super.destroy()
		// console.log('Destroying IntroArch')
		// this.geometry.dispose()
		// this.defaultMaterial.dispose()

		for (let i = 0; i < Object.keys(this.materials).length; i++) {
			this.meshes[name].geometry.dispose()
			this.materials[name].dispose()
		}
		this.depthMaterial.dispose()
		this.godraysMaterial.dispose()

		this.parent.remove(this)
		for (const key in this.assets.textures.arch) {
			this.assets.textures.arch[key].dispose()
		}
		for (const key in this.assets.models) {
			this.assets.models[key].geometry?.dispose()
			this.assets.models[key].material?.dispose()
		}
	}

	load() {
		this.assets.textures.arch = {}

		const basePath = store.publicUrl + 'webgl'

		// const textures = ['base-color', 'normal', 'roughness']

		// textures.forEach(textureName => {
		// 	store.AssetLoader.loadTexture(`${basePath}/textures/arch-${textureName}.png`, { colorSpace: textureName === 'base-color' ? SRGBColorSpace : LinearSRGBColorSpace, flipY: false }).then(texture => {
		// 		this.assets.textures.arch[textureName] = texture
		// 	})
		// })
		//
		// store.AssetLoader.loadGltf(`${basePath}/models/arch.glb`).then(gltf => {
		// 	this.assets.models.arch = gltf.scene.children[0]
		// })

		store.AssetLoader.loadGltf(`${basePath}/models/intro-arch.glb`).then(gltf => {
			for (let i = 0; i < gltf.scene.children.length; i++) {
				const child = gltf.scene.children[i]
				this.assets.models[child.userData.name] = child
			}
		})
	}

	addGui() {
		const groupTheatreObject = store.theatre.helper.addSheetObject(
			this.parent.prettyName,
			`${this.name} / Group`, {
				visible: types.boolean(this.visible),
				transforms: {
					position: types.compound({
						x: types.number(this.position.x, { nudgeMultiplier: 0.1 }),
						y: types.number(this.position.y, { nudgeMultiplier: 0.1 }),
						z: types.number(this.position.z, { nudgeMultiplier: 0.1 })
					}),
					rotation: types.compound({
						x: types.number(this.rotation.x, { nudgeMultiplier: 0.1 }),
						y: types.number(this.rotation.y, { nudgeMultiplier: 0.1 }),
						z: types.number(this.rotation.z, { nudgeMultiplier: 0.1 })
					}),
					scale: types.compound({
						x: types.number(this.scale.x, { nudgeMultiplier: 0.1 }),
						y: types.number(this.scale.y, { nudgeMultiplier: 0.1 }),
						z: types.number(this.scale.z, { nudgeMultiplier: 0.1 })
					})
				}
			}, this)

		groupTheatreObject.onValuesChange(values => {
			this.position.copy(values.transforms.position)
			this.rotation.set(values.transforms.rotation.x, values.transforms.rotation.y, values.transforms.rotation.z)
			this.scale.set(values.transforms.scale.x, values.transforms.scale.y, values.transforms.scale.z)
		}, store.theatre.rafDriver)

		for (let i = 0; i < Object.keys(this.meshes).length; i++) {
			const name = Object.keys(this.meshes)[i]
			const mesh = this.meshes[name]

			if (mesh.isMesh) {
				mesh.name = `${this.name} / ${name}`

				const sheetObject = store.theatre.helper.autoAddObject(mesh, this.parent.prettyName, {
					additionalConfig: {
						color: store.theatre.helper.parseColor(mesh.material.color),
						roughness: types.number(mesh.material.roughness, { range: [0, 1], nudgeMultiplier: 0.01 }),
						specularIntensity: mesh.material.specularIntensity,
						specularColor: store.theatre.helper.parseColor(mesh.material.specularColor)
					}
				})

				sheetObject.onValuesChange(values => {
					mesh.material.color.copy(values.color)
					mesh.material.roughness = values.roughness
					mesh.material.specularIntensity = values.specularIntensity
					mesh.material.specularColor.copy(values.specularColor)
				})
			}
		}
	}
}

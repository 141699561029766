import {
	BufferGeometry,
	DoubleSide,
	InstancedMesh,
	MathUtils,
	MeshPhysicalMaterial,
	Object3D,
	RepeatWrapping,
	SRGBColorSpace,
	Vector3
} from "three"
import createComponent from "./unseen/Component"
import { mergeDeep } from "_utils/index"
import store from '_store'
import { types } from "@theatre/core"

export default class IntroTrees extends createComponent(InstancedMesh) {
	constructor(options = {}) {
		options = mergeDeep({
			name: 'Intro Trees / Mesh',
			count: 10,
			bounds: new Vector3(15, 15, 15),
			materialOpts: {
				uniforms: {}
			}
		}, options)

		super(options,
			new BufferGeometry(),
			new MeshPhysicalMaterial({
				transparent: true,
				roughness: .5,
				side: DoubleSide
			}),
			options.count
		)

		this.material.onBeforeCompile = this.onBeforeCompile.bind(this)
	}

	build() {
		this.options.scene = this.parent

		this.material.map = this.assets.textures.wood
		this.geometry = this.assets.models.tree.geometry

		this.instanceTransforms = []

		const dummy = new Object3D()

		for (let i = 0; i < this.count; i++) {
			dummy.position.set(0, 0, 0)
			dummy.rotation.y = MathUtils.randFloat(0, Math.PI * 2)
			dummy.updateMatrix()

			this.instanceTransforms.push(dummy.clone())
			this.setMatrixAt(i, dummy.matrix)
		}
	}

	onBeforeCompile(shader) {
		shader.defines.USE_VERTEX_COLORS_MAPPING = true
		shader.defines.USE_WIND = true

		Object.assign(shader.uniforms, {
			...this.parent.globalUniforms.fog,
			...store.WebGL.globalUniforms,
			mapAlt: { value: this.assets.textures.leaves }
		})

		shader.vertexShader = shader.vertexShader.replace(
			'#include <color_pars_vertex>',
			`
			#include <color_pars_vertex>
			#ifdef USE_VERTEX_COLORS_MAPPING
				attribute vec3 color_1;
				varying vec3 vColor;
			#endif
			#ifdef USE_WIND
				uniform float uTime;
				#include <snoise_pars>
			#endif
		    `
		)
		shader.vertexShader = shader.vertexShader.replace(
			'#include <color_vertex>',
			`
			#include <color_vertex>
		    #ifdef USE_VERTEX_COLORS_MAPPING
				vColor = color_1;
			#endif
		    `
		)

		shader.vertexShader = shader.vertexShader.replace(
			'#include <begin_vertex>',
			`
			#include <begin_vertex>
		    #ifdef USE_WIND
		    	float noiseMove = uTime * .05;
		    	vec2 noiseUv = (vec4(position, 1.) * modelMatrix * instanceMatrix).xz - 0.5;
		    	float c = cos(noiseMove);
		    	float s = sin(noiseMove);
		    	noiseUv = vec2(
					c * noiseUv.x - s * noiseUv.y,
				    s * noiseUv.x + c * noiseUv.y
				) + 0.5;
		    	float noiseStr = snoise(noiseUv) * vColor.r * .2;
		    	transformed += vec3(noiseStr, 0., noiseStr);
			#endif
		    `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <color_pars_fragment>',
			`
			#include <color_pars_fragment>
			#ifdef USE_VERTEX_COLORS_MAPPING
				varying vec3 vColor;
				uniform sampler2D mapAlt;
			#endif
		    `
		)

		shader.fragmentShader = shader.fragmentShader.replace(
			'#include <map_fragment>',
			`
			#ifdef USE_MAP
			 vec4 sampledDiffuseColor = texture2D( map, vMapUv );
			 #ifdef DECODE_VIDEO_TEXTURE
			  sampledDiffuseColor = sRGBTransferEOTF( sampledDiffuseColor );
			 #endif
			 #ifdef USE_VERTEX_COLORS_MAPPING
				vec4 alt = texture2D( mapAlt, vMapUv );
				sampledDiffuseColor = mix(sampledDiffuseColor, alt, vColor.r);

				if (sampledDiffuseColor.a <= .1) {
					discard;
				}
			 #endif
			 diffuseColor *= sampledDiffuseColor;
			#endif
		    `
		)
	}

	load() {
		const basePath = store.publicUrl + 'webgl'

		const textures = ['wood', 'leaves']

		textures.forEach(name => {
			store.AssetLoader.loadTexture(`${basePath}/textures/intro-tree-${name}.png`, { colorSpace: SRGBColorSpace, flipY: false, wrapping: RepeatWrapping }).then(texture => {
				this.assets.textures[name] = texture
			})
		})

		store.AssetLoader.loadGltf(`${basePath}/models/tree.glb`).then(gltf => {
			this.assets.models.tree = gltf.scene.children[0]
		})
	}

	destroy() {
		super.destroy()
		this.material.dispose()
		this.geometry.dispose()
		this.parent.remove(this)
	}

	addGui() {
		store.theatre.helper.autoAddObject(this, this.parent.prettyName)

		for (let i = 0; i < this.count; i++) {
			const transforms = {
				position: types.compound({
					x: types.number(this.instanceTransforms[i].position.x, { nudgeMultiplier: 0.1 }),
					y: types.number(this.instanceTransforms[i].position.y, { nudgeMultiplier: 0.1 }),
					z: types.number(this.instanceTransforms[i].position.z, { nudgeMultiplier: 0.1 })
				}),
				rotation: types.compound({
					x: types.number(this.instanceTransforms[i].rotation.x, { nudgeMultiplier: 0.1 }),
					y: types.number(this.instanceTransforms[i].rotation.y, { nudgeMultiplier: 0.1 }),
					z: types.number(this.instanceTransforms[i].rotation.z, { nudgeMultiplier: 0.1 })
				})
			}

			const sheetObject = store.theatre.helper.addSheetObject(this.parent.prettyName, `${this.name} / Instance ${i}`, transforms)

			sheetObject.onValuesChange(values => {
				this.instanceTransforms[i].position.set(values.position.x, values.position.y, values.position.z)
				this.instanceTransforms[i].rotation.set(values.rotation.x, values.rotation.y, values.rotation.z)
				this.instanceTransforms[i].updateMatrix()
				this.setMatrixAt(i, this.instanceTransforms[i].matrix)
				this.instanceMatrix.needsUpdate = true
			}, store.theatre.rafDriver)
		}
	}
}
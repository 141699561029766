// #include <default_pars>
// #include <default_frag_pars>
#include <fog_frag_pars>

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vViewPosition;
varying vec4 vWorldPosition;

uniform sampler2D tDiffuse;

uniform float uSaturation;
uniform float uHue;
uniform float uBrightness;

uniform vec2 uResolution;

#include <common>
#include <packing>

#ifdef DEPTH_PACKING
    varying vec2 vHighPrecisionZW;
#endif

vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

void main() {
    #ifdef DEPTH_PACKING
        gl_FragColor = vec4(1.);
    #else
        vec4 diffuseColor = texture2D(tDiffuse, vUv);

        if (diffuseColor.a < 0.5) discard;

        vec3 outgoing = rgb2hsv(diffuseColor.rgb);
        outgoing += vec3(uHue, uSaturation, uBrightness);
        outgoing = hsv2rgb(outgoing);

        gl_FragColor = vec4(outgoing, diffuseColor.a);

    #endif

    #ifdef DEPTH_PACKING
		// Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
        float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        gl_FragColor = packDepthToRGBA(fragCoordZ);
	#endif

    #include <colorspace_fragment>

    #include <fog_frag>
}
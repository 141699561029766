attribute vec3 color_1;

#include <fog_vert_pars>

#include <common>
#include <shadowmap_pars_vertex>
#include <batching_pars_vertex>
#include <snoise_pars>

uniform float uTime;
uniform float uWind;

#ifdef DEPTH_PACKING
	varying vec2 vHighPrecisionZW;
#endif

varying vec2 vUv;
varying vec3 vColor;
varying vec3 vNormal;
varying vec4 vWorldPosition;
varying vec3 vViewPosition;

void main()	{
    vUv = uv;
	vColor = color_1;

	#include <batching_vertex>

	vec4 transformedPosition = vec4(position, 1.0);

	vec3 transformedNormal = normal;
	transformedNormal = normalMatrix * transformedNormal;
	vNormal = normalize(transformedNormal);

	#ifdef USE_BATCHING
		transformedPosition = batchingMatrix * transformedPosition;

		// WIND
		float noiseMove = uTime * .05;
		vec2 noiseUv = (vec4(position, 1.) * modelMatrix * batchingMatrix).xz - 0.5;
		float c = cos(noiseMove);
		float s = sin(noiseMove);
		noiseUv = vec2(
			c * noiseUv.x - s * noiseUv.y,
			s * noiseUv.x + c * noiseUv.y
		) + 0.5;
		float noiseStr = snoise(noiseUv) * vColor.r * uWind;
		transformedPosition.xyz += vec3(noiseStr, 0., noiseStr);

	#elif defined(USE_INSTANCING)
		transformedPosition = instanceMatrix * transformedPosition;
	#endif

	vec4 worldPosition = modelMatrix * transformedPosition;
	vWorldPosition = worldPosition;

	// #include <shadowmap_vertex>

	vec4 mvPosition = modelViewMatrix * transformedPosition;
	vViewPosition = -mvPosition.xyz;

	gl_Position = projectionMatrix * mvPosition;

	#ifdef DEPTH_PACKING
		vHighPrecisionZW = gl_Position.zw;
	#endif

	#include <fog_vert>
}

import { BatchedMesh, MathUtils, Object3D, RepeatWrapping, SRGBColorSpace } from 'three'
import createComponent from './unseen/Component'
import store from '_store'
import { mergeDeep } from '_utils/index'
import VinesMaterial from '_materials/vinesMaterial/VinesMaterial'

export default class OutroVines extends createComponent(Object3D) {
	/**
	 * @private
	 * @type {BatchedMesh}
	 */

	constructor(options = {}) {
		options = mergeDeep({
			name: 'Outro Vines'
		}, options)

		super(options)
	}

	build() {
		this.buildGeometries()
		this.buildMaterial()
		this.buildMesh()
		this.buildLod()
	}

	buildGeometries() {
		this.geometries = {
			vine_1_LOD0: this.assets.models.vine_1_LOD0.geometry,
			vine_1_LOD2: this.assets.models.vine_1_LOD2.geometry,
			vine_2_LOD0: this.assets.models.vine_2_LOD0.geometry,
			vine_2_LOD2: this.assets.models.vine_2_LOD2.geometry
		}
	}

	buildMaterial() {
		this.mat = new VinesMaterial({
			uniforms: {
				tBark: { value: this.assets.textures.vines.bark },
				tLeavesLod0: { value: this.assets.textures.vines['leaves-lod0'] },
				tLeavesLod2: { value: this.assets.textures.vines['leaves-lod2'] },
				...this.parent.globalUniforms.fog
			},
			defines: {
				IS_BATCHED: true
			}
		})
	}

	buildMesh() {
		const vertCount = this.geometries.vine_1_LOD0.attributes.position.count
		let count = 0
		const datasets = ['data-vines_1', 'data-vines_2']
		datasets.forEach((name) => {
			const model = this.assets.models[name]
			const dataset = model.geometry.attributes
			count += dataset._lod0.count
		})

		this.batchedMesh = new BatchedMesh(count, count * vertCount, count * vertCount * 2, this.mat)
		this.batchedMesh.name = this.options.name
	}

	buildLod() {
		const datasets = ['data-vines_1', 'data-vines_2']

		const dummy = new Object3D()

		for (let i = 0; i < datasets.length; i++) {
			const name = datasets[i]
			const model = this.assets.models[name]
			const dataset = model.geometry.attributes
			const count = dataset._lod0.count

			for (let j = 0; j < count; j++) {
				const type = MathUtils.randInt(1, 2)
				const lod = dataset._lod0.array[j] === 1 ? 0 : 2

				const randX = dataset._randvect.array[3 * j]
				const randY = dataset._randvect.array[3 * j + 1]
				const randZ = dataset._randvect.array[3 * j + 2]

				const posX = dataset.position.array[3 * j]
				const posY = dataset.position.array[3 * j + 1]
				const posZ = dataset.position.array[3 * j + 2]

				const instanced = this.batchedMesh.addGeometry(this.geometries[`vine_${type}_LOD${lod}`])

				const rotX = MathUtils.degToRad(15) * randX
				const rotY = MathUtils.degToRad(15) * randY + Math.PI * Math.round(randY)
				const rotZ = MathUtils.degToRad(15) * randZ

				dummy.rotation
					.set(rotX, rotY, rotZ)

				dummy.position
					.set(posX, posY, posZ)
					.add(model.position)

				dummy.updateMatrix()

				this.batchedMesh.setMatrixAt(instanced, dummy.matrix)
			}
		}

		// this.batchedMesh.castShadow = true
		// this.batchedMesh.receiveShadow = true

		this.add(this.batchedMesh)
	}

	load() {
		this.assets.textures.vines = {}
		const textures = ['bark', 'leaves-lod0', 'leaves-lod2']

		textures.forEach(textureName => {
			store.AssetLoader.loadTexture(`${store.publicUrl}webgl/textures/vine-${textureName}.png`, { colorSpace: SRGBColorSpace, wrapping: RepeatWrapping, flipY: false }).then(texture => {
				this.assets.textures.vines[textureName] = texture
			})
		})

		store.AssetLoader.loadGltf(`${store.publicUrl}webgl/models/outro-s1-vines.glb`).then(gltf => {
			for (let i = 0; i < gltf.scene.children.length; i++) {
				const child = gltf.scene.children[i]
				this.assets.models[`${child.name}`] = child
			}
		})

		store.AssetLoader.loadGltf(`${store.publicUrl}webgl/models/outro-s1-vines-data.glb`).then(gltf => {
			for (let i = 0; i < gltf.scene.children.length; i++) {
				const child = gltf.scene.children[i]
				this.assets.models[`data-${child.name}`] = child
			}
		})
	}

	addGui() {
		// this.sheetObject =
		store.theatre.helper.autoAddObject(this.batchedMesh, this.parent.prettyName)
		// 	exclude: [...Object.keys(this.parent.globalUniforms.sun), ...Object.keys(this.parent.globalUniforms.fog), ...Object.keys(this.parent.globalUniforms.shadow)]
		// })
	}

	destroy() {
		super.destroy()
	}
}

#include <default_pars>
#include <default_frag_pars>
#include <packing>

varying vec2 vUv;
varying float vBranchRandom1;
varying float vBranchRandom2;
varying float vProgress;

varying vec3 vPosition;
varying vec2 vScreenSpace;
varying vec3 vViewPosition;
varying vec3 vNormal;
varying vec3 vNormalInverse;
varying mat3 vNormalMatrix;
varying mat3 vNormalMatrixInverse;
varying vec4 vWorldPosition;

uniform vec3 uLightPosition;
uniform vec3 uBaseColor1;
// uniform vec3 uBaseColor2;
// uniform vec3 uBaseColor3;
uniform vec3 uAmbient;
uniform float uShininess;
uniform float uSpecularStrength;
uniform float uOpacity;

uniform float uRevealProgress;

#ifdef DYNAMIC_SHADOWS
    uniform bool uDebugShadow;
    uniform bool receiveShadow;
    #include <shadowmap_pars_fragment>
    #include <shadowmask_pars_fragment>
    uniform vec3 uShadowColor;
    uniform float uShadowIntensity;
#endif

#ifdef DEPTH_PACKING
    varying vec2 vHighPrecisionZW;
#endif

float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}

float circle(in vec2 _st, in float _radius, in float _blur){
    vec2 dist = _st-vec2(0.5);
	return 1.-smoothstep(_radius-(_radius*_blur),
                         _radius+(_radius*0.01),
                         dot(dist,dist)*4.0);
}

void main() {

    float alpha = uOpacity;

    float randomReveal = vBranchRandom1;
    float revealThreshold = 1. - smoothstep(uRevealProgress - 0.1, uRevealProgress, vProgress * randomReveal);

    alpha *= revealThreshold;

    #ifdef DEPTH_PACKING
        gl_FragColor = vec4(vec3(1.), alpha);
    #else

        vec3 normal = normalize(vNormal);

        vec3 lightDirection = normalize(uLightPosition - vWorldPosition.xyz);
        lightDirection *= vNormalMatrixInverse;

        // Add colour variation
        // vec3 color = mix(uBaseColor1, uBaseColor2, smoothstep(0.0, 0.5, vRandom.x));
        // color = mix(color, uBaseColor3, smoothstep(0.5, 1.0, vRandom.x));
        vec3 color = uBaseColor1;

        // Simple diffuse lighting
        float diffuse = clamp(dot(normal.rgb, lightDirection), 0.0, 1.0);

        // Specular highlight
        vec3 viewDir = normalize(cameraPosition - vWorldPosition.xyz);
        vec3 reflectDir = reflect(-lightDirection, normal.rgb);
        float specular = pow(max(dot(viewDir, reflectDir), 0.0), uShininess) * uSpecularStrength;

        /*
        *   Apply basic lighting
        */
        color = (uAmbient + diffuse + specular) * color;

        gl_FragColor = vec4(color, alpha);

    #endif

    #ifdef DEPTH_PACKING
		// Higher precision equivalent of gl_FragCoord.z. This assumes depthRange has been left to its default values.
        float fragCoordZ = 0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5;
        gl_FragColor = packDepthToRGBA(fragCoordZ);
    #else
        #ifdef DYNAMIC_SHADOWS
            gl_FragColor.rgb = mix(gl_FragColor.rgb, gl_FragColor.rgb * uShadowColor, (1.0 - getShadowMask() ) * uShadowIntensity);

            if (uDebugShadow) {
                gl_FragColor.rgb = vec3( getShadowMask() );
            }
        #endif
	#endif

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}
import { mergeDeep } from '_utils'
import BaseMaterial from '../unseen/base/BaseMaterial'
import vertexShader from './vert.glsl'
import fragmentShader from './frag.glsl'
import { DoubleSide } from 'three'

export default class OutroTreeMaterial extends BaseMaterial {
	constructor(options = {}) {
		options = mergeDeep({
			vertexShader,
			fragmentShader,
			uniforms: {
				tDiffuse: { value: null },
				uSaturation: { value: 0, gui: { min: -1, max: 1, step: 0.001 } },
				uBrightness: { value: 0, gui: { min: -1, max: 1, step: 0.001 } },
				uHue: { value: 0, gui: { min: -1, max: 1, step: 0.001 } }
			},
			defines: {
				USE_FOG: true,
				SOLID_FOG: false
			},
			side: DoubleSide,
			transparent: true
		}, options)

		super(options)
	}
}